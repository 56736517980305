import { Expose } from 'class-transformer';
import TransformStringToDecimal from '../../../common/custom-transformers/TransformStringToDecimal';
import Decimal from 'decimal.js';

export class ViewStoreAvailabilityRow {
  @Expose()
  category: string;

  @Expose()
  item: string;

  @Expose()
  description: string;

  @Expose()
  notes: string;

  @Expose()
  sellTo: string;

  @Expose()
  location: string;

  @Expose()
  itemNo: string;

  @Expose()
  sku: string;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  price: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  qty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  reservedQty: Decimal;

  @Expose()
  @TransformStringToDecimal({ toClassOnly: true })
  availableQty: Decimal;
}
