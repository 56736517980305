import { json2csvAsync, Json2CsvOptions } from 'json-2-csv';
import writeExcelFile, { SheetData } from 'write-excel-file';
import {
  getDeliveryRackLabelsReport,
  getOrdersBetweenReport,
  getOrdersDetailsBetweenReport,
  getOrdersSelectedForPicking,
  getShippedCountsBetweenReport,
  getStoreAvailability,
  getStoreAvailabilityBasic,
} from '../api/reports/reports.service';
import { getTimestampAsFileNameString } from '../util/misc-utils';
import { ViewStoreAvailabilityRow } from '../api/reports/dto/view-store-availability-row.dto';
import { ViewStoreAvailabilityBasicRow } from '../api/reports/dto/view-store-availability-basic-row.dto';

export async function downloadOrdersSelectedForPickingReport() {
  const fileTimestamp = getTimestampAsFileNameString();
  const fileName = `orders_selected_for_picking_${fileTimestamp}.csv`;

  const res = await getOrdersSelectedForPicking();
  await saveReport(res, fileName);
}

export async function downloadDeliveryRackLabelsReport() {
  const fileTimestamp = getTimestampAsFileNameString();
  const fileName = `delivery_rack_labels_${fileTimestamp}.csv`;

  const res = await getDeliveryRackLabelsReport();
  await saveReport(res, fileName);
}

export async function downloadOrdersBetweenReport(
  startOrderWeek: string,
  endOrderWeek: string,
) {
  try {
    const fileTimestamp = getTimestampAsFileNameString();
    const fileName = `orders_${startOrderWeek}_${endOrderWeek}_asof_${fileTimestamp}.csv`;

    const res = await getOrdersBetweenReport(startOrderWeek, endOrderWeek);

    await saveReport(res, fileName);
  } catch (e) {
    console.log('Error downloading report.');
  }
}

export async function downloadOrdersDetailsBetweenReport(
  startOrderWeek: string,
  endOrderWeek: string,
) {
  try {
    const fileTimestamp = getTimestampAsFileNameString();
    const fileName = `orders_details_${startOrderWeek}_${endOrderWeek}_asof_${fileTimestamp}.csv`;

    const res = await getOrdersDetailsBetweenReport(
      startOrderWeek,
      endOrderWeek,
    );

    //Make UPC a string to prevent scientific notation display.
    const data = res.map((r) => ({ ...r, upc: `_${r.upc}_` }));

    await saveReport(data, fileName);
  } catch (e) {
    console.log('Error downloading report.');
  }
}

export async function downloadShippedCountsBetweenReport(
  startWeek: string,
  endWeek: string,
) {
  try {
    const fileTimestamp = getTimestampAsFileNameString();
    const fileName = `shipped_counts_${startWeek}_${endWeek}_asof_${fileTimestamp}.csv`;

    const res = await getShippedCountsBetweenReport(startWeek, endWeek);

    await saveReport(res, fileName);
  } catch (e) {
    console.log('Error downloading report.');
  }
}

export async function downloadStoreAvailability() {
  const fileTimestamp = getTimestampAsFileNameString();
  const fileName = `Popes Availability as of ${fileTimestamp}.xlsx`;

  const res = await getStoreAvailability();

  const borders = {
    bottomBorderColor: '#222222',
    bottomBorderStyle: 'thin',
    leftBorderStyle: 'none',
    rightBorderStyle: 'none',
    topBorderStyle: 'none',
  };

  const schema = [
    {
      column: 'Category',
      type: String,
      value: (r: ViewStoreAvailabilityRow) => r.category,
      width: 17,
      wrap: true,
      ...borders,
    },
    {
      column: 'Item',
      type: String,
      value: (r: ViewStoreAvailabilityRow) => r.item,
      width: 55,
      wrap: true,
      ...borders,
    },
    {
      column: 'Description',
      type: String,
      value: (r: ViewStoreAvailabilityRow) => r.description,
      width: 25,
      wrap: true,
      ...borders,
      fontSize: 7,
    },
    {
      column: 'Location',
      type: String,
      value: (r: ViewStoreAvailabilityRow) => r.location,
      width: 9,
      ...borders,
    },
    {
      column: 'Price',
      type: Number,
      value: (r: ViewStoreAvailabilityRow) =>
        r.price.toDecimalPlaces(2).toNumber(),
      width: 10,
      format: '$#,#0.00',
      ...borders,
    },
    {
      column: 'Available Qty',
      type: Number,
      value: (r: ViewStoreAvailabilityRow) => r.availableQty.toNumber(),
      width: 13,
      ...borders,
    },
    {
      column: 'Notes',
      type: String,
      value: (r: ViewStoreAvailabilityRow) =>
        `${r?.sellTo || ''} ${r?.notes || ''}`.trim(),
      width: 17,
      wrap: true,
      ...borders,
      fontSize: 7,
    },
  ];

  // @ts-ignore borderstyles are allowed
  writeExcelFile(res, {
    schema,
    stickyRowsCount: 1,
    headerStyle: {
      backgroundColor: '#2c6b39',
      color: '#ffffff',
      fontWeight: 'bold',
    },
    fontSize: 9.5,
    fileName: fileName,
    orientation: 'landscape',
  });
}

export async function downloadStoreAvailabilityBasic() {
  const fileTimestamp = getTimestampAsFileNameString();
  const fileName = `Popes Availability as of ${fileTimestamp}.xlsx`;

  const res = await getStoreAvailabilityBasic();

  const borders = {
    bottomBorderColor: '#222222',
    bottomBorderStyle: 'thin',
    leftBorderStyle: 'none',
    rightBorderStyle: 'none',
    topBorderStyle: 'none',
  };

  const schema = [
    {
      column: 'Category',
      type: String,
      value: (r: ViewStoreAvailabilityBasicRow) => r.category,
      width: 17,
      wrap: true,
      ...borders,
    },
    {
      column: 'Item',
      type: String,
      value: (r: ViewStoreAvailabilityBasicRow) => r.item,
      width: 55,
      wrap: true,
      ...borders,
    },
    {
      column: 'Available Qty',
      type: Number,
      value: (r: ViewStoreAvailabilityBasicRow) => r.availableQty.toNumber(),
      width: 13,
      ...borders,
    },
  ];

  // @ts-ignore borderstyles are allowed
  writeExcelFile(res, {
    schema,
    stickyRowsCount: 1,
    headerStyle: {
      backgroundColor: '#2c6b39',
      color: '#ffffff',
      fontWeight: 'bold',
    },
    fontSize: 9.5,
    fileName: fileName,
    orientation: 'landscape',
  });
}

async function saveReport(
  data: any,
  fileName: string,
  options?: Json2CsvOptions,
) {
  const defaultOptions = {
    emptyFieldValue: '',
  };

  if (!options) {
    options = { ...defaultOptions };
  }

  try {
    const csv = await json2csvAsync(data, { emptyFieldValue: '' });

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    // @ts-ignore
    if (navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log('Error downloading report.');
  }
}
